import { Language, Site } from '@getaccept/lib-shared-new/src/languages/enums/translation';

export class TranslationHelper {
  static getLangObjectsForProject(site: string): { key: string; id: string; file: string }[] {
    switch (site) {
      case Site.Signup:
      case Site.Login:
      case Site.MyDocuments:
        return this.getLanguagesFromProject(
          [
            Language.Da,
            Language.En,
            Language.Fr,
            Language.No,
            Language.Nb,
            Language.Nn,
            Language.Sv,
            Language.Es,
          ],
          site
        );
      case Site.AppSite:
        return this.getLanguagesFromProject(
          [Language.Da, Language.En, Language.Fr, Language.No, Language.Sv, Language.Es],
          site
        );
      case Site.RecipientPage:
        return this.getLanguagesFromProject(
          [
            Language.En,
            Language.Sv,
            Language.Fr,
            Language.No,
            Language.Nb,
            Language.Nn,
            Language.Da,
            Language.Fi,
            Language.De,
            Language.Pl,
            Language.Es,
            Language.It,
            Language.Pt,
            Language.Ru,
            Language.Nl,
            Language.ZhCn,
          ],
          site
        );
    }
  }

  static getLanguagesFromProject(
    languageKeys: string[],
    site: string
  ): { key: string; id: string; file: string }[] {
    const languages: { key: string; id: string; file: string }[] = [
      {
        key: `sv_SE/getaccept-${site}-sv_SE.json`,
        id: Language.Sv,
        file: `getaccept-${site}-sv-SE`,
      },
      {
        key: `da_DK/getaccept-${site}-da_DK.json`,
        id: Language.Da,
        file: `getaccept-${site}-da-DK`,
      },
      {
        key: `en_GB/getaccept-${site}-en_GB.json`,
        id: Language.En,
        file: `getaccept-${site}-en-GB`,
      },
      {
        key: `fr_FR/getaccept-${site}-fr_FR.json`,
        id: Language.Fr,
        file: `getaccept-${site}-fr-FR`,
      },
      {
        key: `no_NO/getaccept-${site}-no_NO.json`,
        id: Language.No,
        file: `getaccept-${site}-no-NO`,
      },
      {
        key: `no_NO/getaccept-${site}-no_NO.json`,
        id: Language.Nb,
        file: `getaccept-${site}-no-NO`,
      },
      {
        key: `no_NO/getaccept-${site}-no_NO.json`,
        id: Language.Nn,
        file: `getaccept-${site}-no-NO`,
      },
      {
        key: `de_DE/getaccept-${site}-de_DE.json`,
        id: Language.De,
        file: `getaccept-${Site.RecipientPage}-de-DE`,
      },
      {
        key: `pl_PL/getaccept-${site}-pl_PL.json`,
        id: Language.Pl,
        file: `getaccept-${Site.RecipientPage}-pl-PL`,
      },
      {
        key: `es/getaccept-${site}-es.json`,
        id: Language.Es,
        file: `getaccept-${site}-es`,
      },
      {
        key: `it_IT/getaccept-${site}-it_IT.json`,
        id: Language.It,
        file: `getaccept-${Site.RecipientPage}-it-IT`,
      },
      {
        key: `pt_PT/getaccept-${site}-pt_PT.json`,
        id: Language.Pt,
        file: `getaccept-${Site.RecipientPage}-pt-PT`,
      },
      {
        key: `ru_RU/getaccept-${site}-ru_RU.json`,
        id: Language.Ru,
        file: `getaccept-${Site.RecipientPage}-ru-RU`,
      },
      {
        key: `nl_NL/getaccept-${site}-nl_NL.json`,
        id: Language.Nl,
        file: `getaccept-${Site.RecipientPage}-nl-NL`,
      },
      {
        key: `fi_FI/getaccept-${site}-fi_FI.json`,
        id: Language.Fi,
        file: `getaccept-${Site.RecipientPage}-fi-FI`,
      },
      {
        key: `zh_CN/getaccept-${site}-zh_CN.json`,
        id: Language.ZhCn,
        file: `getaccept-${Site.RecipientPage}-zh-CN`,
      },
    ];

    return languages.filter(language => languageKeys.includes(language.id));
  }

  static getLangObject(lang: string, project: string): { key: string; id: string; file: string } {
    const languages = TranslationHelper.getLangObjectsForProject(project);

    const language: { key: string; id: string; file: string } = languages.find(
      language => language.id === lang
    );
    return language || languages.find(language => language.id === Language.En);
  }
}
