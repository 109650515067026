<template>
  <transition name="appear" mode="out-in">
    <LoginSignupContainer v-if="loaded">
      <router-view v-slot="{ Component }">
        <transition name="appear" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </LoginSignupContainer>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref, onBeforeMount } from 'vue';
import LoginSignupContainer from '@getaccept/lib-shared-new/src/login-signup/components/LoginSignupContainer.vue';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { VueRouterRoutingProvider } from '@bugsnag/vue-router-performance';
import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';
import { userLanguage } from './helpers/language';
import { loadLanguageAsync } from './i18n/lang';
import imageUrl from './assets/img/carousel-1.svg?url';
import router from './router/router';

export default defineComponent({
  components: {
    LoginSignupContainer,
  },
  setup() {
    const loaded = ref(false);
    const unhandledrejectionHandler = event => {
      if (bugsnagClient) {
        bugsnagClient.notify(event);
      }
    };

    window.addEventListener('unhandledrejection', unhandledrejectionHandler);

    onBeforeMount(() => {
      if (location.host === 'login.getaccept.com' || location.host === 'login.ga-staging.com') {
        window.location.replace(`https://${location.host.replace('login.', 'app.')}`);
      }
    });

    const initBugSnagPerformanceMonitoring = () => {
      if (import.meta.env.VUE_APP_BUGSNAG) {
        BugsnagPerformance.start({
          apiKey: import.meta.env.VUE_APP_BUGSNAG,
          releaseStage: import.meta.env.VUE_APP_BUGSNAG_RELEASE_STAGE || 'development',
          appVersion: import.meta.env.VUE_APP_BUGSNAG_APP_VERSION || '0.0.0',
          routingProvider: new VueRouterRoutingProvider(router as any, '/'),
        });
      }
    };

    onMounted(async () => {
      await loadLanguageAsync(userLanguage());
      initBugSnagPerformanceMonitoring();
      loaded.value = true;
    });

    onBeforeUnmount(() => {
      window.removeEventListener('unhandledrejection', unhandledrejectionHandler);
    });

    return {
      loaded,
      imageUrl,
    };
  },
});
</script>

<style lang="scss">
body {
  padding: 0;
  margin: 0;
}

.grecaptcha-badge {
  visibility: collapse !important;
}
</style>
