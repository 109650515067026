<template>
  <div class="reset-password">
    <FriedH2 class="title branding">{{ $t('your-password-has-expired') }}</FriedH2>
    <FriedParagraph class="description">
      {{ $t('password-expired-description') }}
    </FriedParagraph>
    <FriedMessage
      v-if="error"
      data-external="password-expired-error-div"
      class="error"
      :type="MessageType.Error"
      :title="$t(error.title)"
    >
      <i18n-t :keypath="error.description">
        <FriedLink
          :normal="true"
          place="contact-support-link"
          href="http://help.getaccept.com/"
          target="_blank"
        >
          {{ $t('contact-support') }}
        </FriedLink>
      </i18n-t>
    </FriedMessage>
    <form @submit.prevent="handleSubmit">
      <FriedInput
        v-model="oldPassword"
        data-external="password-expired-old-password-input"
        :type="oldPasswordInputType"
        class="login-input"
        :label="$t('old-password')"
        :error-message="oldPasswordError"
        autofocus
        autocomplete="current-password"
      >
        <template #append>
          <button
            type="button"
            class="view-password-button"
            :title="$t('show-and-hide-password')"
            tabindex="-1"
            @click.prevent="toggleViewOldPassword"
          >
            <FriedIcon v-if="!viewOldPassword" key="1" aria-hidden icon="view" />
            <FriedIcon v-else key="2" aria-hidden icon="view-hide" />
          </button>
        </template>
      </FriedInput>
      <FriedInput
        v-model="newPassword"
        data-external="password-expired-new-password-input"
        :type="newPasswordInputType"
        class="login-input"
        :error-message="errorMessage"
        :label="$t('new-password')"
        autocomplete="new-password"
      >
        <template #append>
          <button
            type="button"
            class="view-password-button"
            :title="$t('show-and-hide-password')"
            tabindex="-1"
            @click.prevent="toggleViewNewPassword"
          >
            <FriedIcon v-if="!viewNewPassword" key="1" aria-hidden icon="view" />
            <FriedIcon v-else key="2" aria-hidden icon="view-hide" />
          </button>
        </template>
      </FriedInput>
      <FriedInput
        v-model="newPasswordRepeat"
        data-external="password-expired-new-password-repeat-input"
        :type="newPasswordRepeatInputType"
        class="login-input"
        :label="$t('confirm-password')"
        :help-message="$t('password-must-include')"
        :error-message="passwordErrorMessage"
        autocomplete="confirm-new-password"
      >
        <template #append>
          <button
            type="button"
            class="view-password-button"
            :title="$t('show-and-hide-password')"
            tabindex="-1"
            @click.prevent="toggleViewNewPasswordRepeat"
          >
            <FriedIcon v-if="!viewNewPasswordRepeat" key="1" aria-hidden icon="view" />
            <FriedIcon v-else key="2" aria-hidden icon="view-hide" />
          </button>
        </template>
      </FriedInput>
      <PasswordValidator
        :input="newPassword"
        class="password-validator"
        :show-errors="showValidatorError"
        @is-valid-password="handleIsValidPassword"
      />
      <FriedButton
        :loading="loading"
        class="submit-button"
        data-external="password-expired-submit-button"
        type="submit"
      >
        {{ $t('update-password') }}
      </FriedButton>
    </form>
  </div>
</template>

<script lang="ts">
import { InputType, MessageType } from '@getaccept/fried-tofu';

import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import type { AxiosError } from 'axios';

import { RecaptchaAction } from '@getaccept/lib-shared-new/src/recaptcha/recaptcha-action';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import bugsnagClient from '@getaccept/lib-shared-new/src/bugsnag';
import type { LoginResponse } from '../../api/login/types/login-response';
import { LoginService } from '../../api/login/login.service';
import { RecaptchaService } from '../../api/recaptcha/recaptcha.service';
import { getErrorMessage } from '../../helpers/error-message';
import { ErrorKey } from '../../types/enums/error-key';
import type { ErrorMessage } from '../../types/error-message';
import { PasswordValidation } from '../../helpers/password-validation';
import { PasswordExpiredErrorCode } from '../types/enums/password-expired-error-code';
import { PasswordExpiredError } from '../types/enums/password-expired-error';
import { useLogin } from '../../login/composable/login.composable';
import PasswordValidator from '../../common/components/PasswordValidator.vue';

export default defineComponent({
  components: { PasswordValidator },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { loginSuccess, goUrl } = useLogin();
    const oldPassword = ref('');
    const newPassword = ref('');
    const newPasswordRepeat = ref('');
    const viewOldPassword = ref(false);
    const viewNewPassword = ref(false);
    const viewNewPasswordRepeat = ref(false);
    const oldPasswordError = ref('');
    const errorMessage = ref('');
    const passwordMatchError = ref('');
    const showValidatorError = ref(false);
    const isValidPassword = ref(false);
    const error = ref<ErrorMessage>(null);
    const loading = ref(false);
    const { t } = useI18n();
    watch(oldPassword, () => {
      oldPasswordError.value = '';
    });
    watch(newPassword, () => {
      errorMessage.value = '';
      passwordMatchError.value = '';
    });
    watch(newPasswordRepeat, () => {
      passwordMatchError.value = '';
    });
    const passwordErrorMessage = computed(
      () => errorMessage.value || passwordMatchError.value || ''
    );
    const oldPasswordInputType = computed(() => getInputType(viewOldPassword.value));
    const newPasswordInputType = computed(() => getInputType(viewNewPassword.value));
    const newPasswordRepeatInputType = computed(() => getInputType(viewNewPasswordRepeat.value));
    const getInputType = (view: boolean): InputType => (view ? InputType.Text : InputType.Password);
    const toggleViewOldPassword = () => {
      viewOldPassword.value = !viewOldPassword.value;
    };
    const toggleViewNewPassword = () => {
      viewNewPassword.value = !viewNewPassword.value;
    };
    const toggleViewNewPasswordRepeat = () => {
      viewNewPasswordRepeat.value = !viewNewPasswordRepeat.value;
    };
    const validateOldPassword = (password: string): string => {
      if (!password) {
        return t('password-is-required').toString();
      }
      return '';
    };
    const setGoUrl = () => {
      if (route.query.go) {
        goUrl.value = `${route.query.go}`;
        return;
      }

      goUrl.value = route.fullPath;
    };
    const handleIsValidPassword = (isValid: boolean) => {
      isValidPassword.value = isValid;
    };
    const resetExpiredPassword = async ({
      oldPassword,
      newPassword,
      newPasswordRepeat,
    }: {
      oldPassword: string;
      newPassword: string;
      newPasswordRepeat: string;
    }) => {
      loading.value = true;
      try {
        const recaptchaToken: string = await RecaptchaService.getToken(RecaptchaAction.Login);
        const email = router.currentRoute.value.query.email
          ? `${router.currentRoute.value.query.email}`
          : '';
        const data: LoginResponse = await LoginService.expired({
          oldPassword,
          newPassword,
          newPasswordRepeat,
          email,
          recaptchaToken,
        });
        const { status, entityCount, authMethod, authEntityId }: LoginResponse = data;
        if (status !== 1) {
          resetExpiredPasswordFailed({ data });
          loading.value = false;
          return;
        }
        loginSuccess({
          entityCount,
          authMethod,
          authEntityId,
        });
      } catch (error) {
        resetExpiredPasswordFailed({ requestError: error });
      }
      loading.value = false;
    };
    const resetExpiredPasswordFailed = ({
      data,
      requestError,
    }: {
      data?: LoginResponse;
      requestError?: AxiosError;
    }) => {
      let newError: ErrorMessage;
      const errorCode: number = requestError?.response?.status;
      const errorMessage: string = data?.error;
      if (requestError) {
        if (errorCode === PasswordExpiredErrorCode.LongerBetweenAttempts) {
          newError = getErrorMessage(ErrorKey.Throttled);
        } else {
          newError = getErrorMessage(ErrorKey.Unknown);
          if (bugsnagClient) {
            bugsnagClient.notify(requestError, event => {
              event.groupingHash = JSON.stringify(requestError);
            });
          }
        }
      } else {
        switch (errorMessage) {
          case PasswordExpiredError.Throttled:
            newError = getErrorMessage(ErrorKey.Throttled);
            break;
          case PasswordExpiredError.WeakPassword:
            newError = getErrorMessage(ErrorKey.WeakPassword);
            break;
          case PasswordExpiredError.SamePassword:
            newError = getErrorMessage(ErrorKey.SamePassword);
            break;
          case PasswordExpiredError.IncorrectPassword:
            newError = getErrorMessage(ErrorKey.InvalidPassword);
            break;
          case PasswordExpiredError.UpdateFailed:
          case PasswordExpiredError.InvalidUser:
          default:
            newError = getErrorMessage(ErrorKey.Unknown);
            if (bugsnagClient) {
              bugsnagClient.notify(new Error(JSON.stringify(data)), event => {
                event.groupingHash = JSON.stringify(data);
              });
            }
        }
      }
      error.value = newError;
    };
    const handleSubmit = () => {
      showValidatorError.value = true;
      oldPasswordError.value = validateOldPassword(oldPassword.value);
      errorMessage.value = PasswordValidation.validateNewPassword(
        newPassword.value,
        isValidPassword.value
      );
      passwordMatchError.value = PasswordValidation.validateNewPasswordRepeat(
        newPassword.value,
        newPasswordRepeat.value
      );
      if (errorMessage.value || passwordMatchError.value || oldPasswordError.value) {
        return;
      }
      resetExpiredPassword({
        oldPassword: oldPassword.value,
        newPassword: newPassword.value,
        newPasswordRepeat: newPasswordRepeat.value,
      });
    };
    onMounted(() => {
      setGoUrl();
    });
    return {
      handleSubmit,
      handleIsValidPassword,
      error,
      loading,
      passwordErrorMessage,
      oldPassword,
      oldPasswordInputType,
      oldPasswordError,
      newPasswordInputType,
      newPasswordRepeatInputType,
      toggleViewOldPassword,
      toggleViewNewPassword,
      toggleViewNewPasswordRepeat,
      viewOldPassword,
      newPassword,
      errorMessage,
      viewNewPassword,
      newPasswordRepeat,
      viewNewPasswordRepeat,
      showValidatorError,
      isValidPassword,
      passwordMatchError,
      resetExpiredPasswordFailed,
      MessageType,
    };
  },
});
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: var(--spacing-50);
}

.description {
  color: var(--text-gray);
  padding-bottom: var(--spacing-150);
}

.login-input {
  width: 100%;
  margin-bottom: var(--spacing-50);
}

.password-validator {
  margin-top: var(--spacing-50);
  margin-bottom: var(--spacing-150);
}

.view-password-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;

  &:focus:not([data-focus-visible-added]) {
    outline: none;
  }
}

.error {
  margin-bottom: var(--spacing-50);
}

.submit-button {
  width: 100%;
}
</style>
