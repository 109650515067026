<template>
  <div class="pin-container">
    <input
      v-for="i in pinLength"
      :key="i"
      :value="values[i - 1]"
      :disabled="disabled"
      :class="['pin-input', { error }]"
      data-external="sms-auth-container-pin-input"
      pattern="\d*"
      :type="InputType.Number"
      :maxlength="1"
      @paste.prevent="handlePaste($event, i)"
      @keydown.delete.prevent="handleDelete($event, i)"
      @keydown.backspace.prevent="handleDelete($event, i)"
      @keydown.left="handleFocus($event, i)"
      @keydown.right="handleFocus($event, i)"
      @keydown.up.prevent
      @keydown.down.prevent
      @focus="$event => select($event)"
      @input="handleInput($event, i)"
    />
  </div>
</template>
<script lang="ts">
import { BrowserHelper } from '@getaccept/lib-shared-new/src/chat/helpers/browser.helper';
import { InputType } from '@getaccept/lib-shared-new/src/enums/InputType';
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  props: {
    pinLength: {
      type: Number,
      default: 6,
    },
    disabled: { type: Boolean },
    error: { type: Boolean },
  },
  emits: ['pin'],
  setup(props, { emit }) {
    const values = ref<string[]>([]);

    const watchingValues = () => {
      emit('pin', values.value.join(''));
    };
    watch(values, watchingValues, { deep: true });

    const handleInsertText = (value, elementNumber: number) => {
      setSpecificValue(value, elementNumber);
      if (elementNumber < props.pinLength) {
        const element: HTMLInputElement = inputElement(elementNumber);
        element.focus();
      } else {
        const element: HTMLInputElement = inputElement(elementNumber - 1);
        element.blur();
      }
    };

    const handleIPhoneInputFromSMS = (value: string, elementNumber: number) => {
      setSpecificValue(value, elementNumber);
      if (value.length === props.pinLength) {
        values.value = Array.from(value.toString());
      }
    };

    const handlePaste = (event: ClipboardEvent, elementNumber: number) => {
      const input: string = event.clipboardData.getData('Text');
      const inputs: string[] = input.split('');
      if (inputs.length >= props.pinLength) {
        values.value = inputs.slice(0, props.pinLength);
      } else {
        const valueStart: number = elementNumber - 1;
        const inputCount: number = props.pinLength - elementNumber + 1;
        values.value.splice(valueStart, inputs.length, ...inputs.slice(0, inputCount));
      }
    };

    const handleInput = (event: any, elementNumber: number) => {
      if (event.isTrusted === false) {
        handleIPhoneInputFromSMS(event.target.value, elementNumber);
        return;
      }
      if (event.inputType === 'insertText' || BrowserHelper.isIE(window.navigator)) {
        handleInsertText(event.target.value, elementNumber);
      }
    };

    const setSpecificValue = (value: string, elementNumber: number) => {
      values.value.splice(elementNumber - 1, 1, value);
    };

    const handleFocus = (event: KeyboardEvent, elementNumber: number) => {
      switch (event.key) {
        case 'ArrowLeft': {
          if (elementNumber === 1) {
            return;
          }
          const element: HTMLInputElement = inputElement(elementNumber - 2);
          element.focus();
          return;
        }
        case 'ArrowRight': {
          if (elementNumber === props.pinLength) {
            return;
          }
          const element: HTMLInputElement = inputElement(elementNumber);
          element.focus();
          return;
        }
      }
    };

    const handleDelete = (event: any, elementNumber: number) => {
      const index: number = elementNumber - 1;
      event.target.value = '';
      values.value.splice(index, 1, '');
      if (index > 0) {
        const element: HTMLInputElement = inputElement(index - 1);
        element.focus();
      }
    };

    const inputElement = (index: number): HTMLInputElement =>
      document.getElementsByClassName('pin-input')[index] as HTMLInputElement;

    const select = (event: Event) => {
      (event.target as HTMLInputElement).select();
    };

    return {
      InputType,
      values,
      handleInsertText,
      handleIPhoneInputFromSMS,
      handlePaste,
      handleInput,
      setSpecificValue,
      handleFocus,
      handleDelete,
      inputElement,
      watchingValues,
      select,
    };
  },
});
</script>
<style scoped lang="scss">
$border: 1px solid var(--gray-89);
$input-height: 51px;
$input-width: 40px;
$font-size: 20px;

.pin-container {
  display: flex;
  padding: var(--spacing-50) 0;

  .pin-input {
    border: none;
    border-top: $border;
    border-bottom: $border;
    border-right: $border;
    width: $input-width;
    height: $input-height;
    text-align: center;
    font-size: $font-size;
    border-radius: 0;
    caret-color: transparent;
    appearance: none;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      appearance: textfield;
    }

    &:first-child {
      border-left: $border;
      border-radius: var(--radius-small) 0 0 var(--radius-small);
    }

    &:last-child {
      border-radius: 0 var(--radius-small) var(--radius-small) 0;
    }

    &:focus {
      outline: none;
      border: 2px var(--info-dark) solid;
    }

    &.error {
      border-color: var(--error-dark);
    }
  }
}
</style>
